import React from 'react';
import './HeaderQuiz.css';
import ProgressBar from '../ProgressBar';

const HeaderQuiz = ({ questionNumber, totalQuestions }) => {
  return (
    <header className='header-quiz_wrapper'>
      <div  className="header-quiz">
      <img src="/assets/images/logo256.png" alt="Logo" className="header-quiz__logo" />
      {questionNumber && totalQuestions && (
        <div className="header-quiz__counter">
          {questionNumber} out of {totalQuestions}
        </div>
      )}</div>
      <ProgressBar completed={questionNumber - 1} total={totalQuestions} />
    </header>
  );
};

export default HeaderQuiz;
