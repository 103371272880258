const questionsData = [

    {
        id: 'age',
        type: 'single',
        layout: 'two-columns',
        question: 'How old are you?',
        // description: 'This information will help us assess your personal procrastination score.',
        options: ['18-24', '25-34', '35-44', '45+'],
        optionImages: [
            '../assets/images/gender/male1.png',
            '../assets/images/gender/male2.png',
            '../assets/images/gender/male3.png',
            '../assets/images/gender/male4.png',
        ],
        optionImagesSize: '96px',
        transition: '',
        // image: '../assets/images/question-illustrations/q1-alt.png',
    },
    {
        type: 'single',
        question: 'How much do you know about making money with AI?',
        options: [
            "I'm an expert",
            "I know a thing or two",
            "I'm a beginner"
        ],
        optionImages: [
            '../../assets/images/question-answers/light-bulb.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/thinking-face.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: `What is your ultimate goal?`,
        description: 'Select your top priority to achieve.',
        options: ['Grow wealth', 'Be my own boss', 'Travel more', 'Achieve a personal goal'],
        optionImages: [
            '../../assets/images/question-answers/money-bag.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/globe.png',
            '../../assets/images/question-answers/target.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        // layout: 'split',
        // image: '../../assets/images/question-illustrations/q5.png',
        question: `What's your current work situation?`,
        description: 'Please select your main source of income.',
        options: ['Full-time employee', 'Part-time employee', 'Self-employed', 'Unemployed', 'Student'],
        optionImages: [
            '../../assets/images/question-answers/man-office-worker.png',
            '../../assets/images/question-answers/hourglass-not-done.png',
            '../../assets/images/question-answers/house.png',
            '../../assets/images/question-answers/prohibited.png',
            '../../assets/images/question-answers/graduation-cap.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        // image: '../../assets/images/question-illustrations/q13.png',
        question: 'Do you find yourself doing a lot of manual tasks at work?',
        options: [`Yes`, `No`, `I don't know`]
    },    
    {
        // id: 'last-minute',
        type: 'single',
        question: 'How often do you feel overwhelmed by your workload?',
        layout: 'two-columns',
        description: '',
        options: [
            `Daily`, `Weekly`, 'Monthly', `Rarely`
        ],        
        optionImages: [
            '../../assets/images/question-answers/pensive.png',
            '../../assets/images/question-answers/confused.png',
            '../../assets/images/question-answers/expressionless.png',
            '../../assets/images/question-answers/smiling.png',
        ],
        optionImagesSize: '48px',
    },

    {
        type: 'single',
        question: 'Do you struggle to balance work and family time?',
        layout: 'split',
        description: '',
        options: ['Absolutely', 'Somewhat', 'I have work-life balance', `Prefer not to say`],
        image: '../../assets/images/question-illustrations/q5.png',
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q2.png',
        question: 'How often do you work late or on weekends?',
        options: [            'Always',
            'Often',
            'Sometimes',
            'Rarely',
            'Never'
        ]
    },
    {
        type: 'single',
        image: '../../assets/images/question-illustrations/q13.png',
        question: 'Would you like a job where routine tasks are done for you?',
        options: ['Absolutely',
                'Yes, somewhat',
                'Maybe',
                'Not really']
    },
    {
        type: 'single',
        layout: 'two-columns',
        question: 'Do you wish you could travel while working?',
        description: '',
        options: [`It's a dream`, `I'm traveling`, `Sometimes`, `Not a priority`],
        image: '../../assets/images/question-illustrations/q6.png',
    },
    {
        type: 'single',
        layout: 'single-column',
        question: 'Are you comfortable with learning new skills?',
        description: '',
        options: [`Yes`, `No`],
        image: '../../assets/images/question-illustrations/q10.png',
    },
    {
        type: 'single',
        question: 'Rate your knowledge in content writing',
        description: 'According to Upwork, freelance content writers make around $42,000 annually.',
        options: ['I need more information', 'I know a thing or two', 'I know everything'],
        // image: '../assets/images/question-illustrations/q6.png',
        optionImages: [
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',

    },
    // testimonial
    {
        type: 'single',
        question: 'Rate your knowledge in digital marketing',
        description: 'According to Upwork, digital marketers make around $100,000 anually.',
        options: ['I need more information', 'I know a thing or two', 'I know everything'],
        optionImages: [
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/books.png',
            '../../assets/images/question-answers/light-bulb.png',
        ],
        optionImagesSize: '48px',
    },

    // {
    //     type: 'single',
    //     question: 'Rate your ability to promote business',
    //     description: '70% of freelancers find their job online, and you can too.',
    //     options: ['I need more information', 'I know a thing or two', `I'm totally prepared`],
    //     optionImages: [
    //         '../../assets/images/question-answers/thinking-face.png',
    //         '../../assets/images/question-answers/books.png',
    //         '../../assets/images/question-answers/light-bulb.png',
    //     ],
    //     optionImagesSize: '48px',

    // },

    {
        type: 'multi',
        question: `Choose jobs you might be interested in`,
        description: 'Choose as many as you like',
        options: ['Content creation', 'Coaching', 'Social Media Marketing', 'Dropshipping', 'Copywriting', 'Translation', 'Graphic Design', 'Blogging', 'Illustration', 'No-code Apps', 'None of the above'],
        optionImages: [
            '../../assets/images/question-answers/laptop.png',
            '../../assets/images/question-answers/briefcase.png',
            '../../assets/images/question-answers/phone.png',
            '../../assets/images/question-answers/package.png',
            '../../assets/images/question-answers/writing-hand.png',
            '../../assets/images/question-answers/fountain-pen.png',
            '../../assets/images/question-answers/globe-with-meridians.png',
            '../../assets/images/question-answers/artist-palette.png',
            '../../assets/images/question-answers/memo.png',
            '../../assets/images/question-answers/paintbrush.png',
            '../../assets/images/question-answers/magnifying-glass.png'
        ],
        optionImagesSize: '32px',
        // image: '../assets/images/question-illustrations/q12.png',
    },


    {
        type: 'single',
        question: 'What big dream are you working towards?',
        options: ['Buy a house', 'Extended Vacation', 'Get a new car', 'Enjoy worry-free retirement', 'Other'],
        optionImages: [
            '../../assets/images/question-answers/house.png',
            // '../../assets/images/question-answers/wedding.png',
            '../../assets/images/question-answers/vacation.png',
            '../../assets/images/question-answers/car.png',
            '../../assets/images/question-answers/couch.png',
            '../../assets/images/question-answers/question-mark.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend to achieve your goal?',
        options: ['⏱️ 5 min/day', '⏱️ 10 min/day', '⏱️ 15 min/day', '⏱️ 20 min/day'],
    }
];

export default questionsData;
