import React from 'react';
import './ReassurancePage.css';
import { useNavigate } from 'react-router-dom';
import HeaderQuiz from '../components/HeaderQuiz/HeaderQuiz';
import ProgressBar from '../components/ProgressBar';
import { Link } from 'react-router-dom';

const ReassurancePage = ({ answers, questionNumber, totalQuestions, basePath }) => {
    const navigate = useNavigate();
  const answerForQuestion2 = answers[1];

  const getContent = () => {
    if (answerForQuestion2 === 'Option A') {
      return {
        headline: `You're not alone`,
        paragraph: 'Paragraph text for Option A',
      };
    } else {
      return {
        headline: ``,
        paragraph: `Careeristo harnesses the power of behavioral science to provide you with a tailor-made plan, transforming your habits for lasting change and success.
        `,
      };
    }
  };

  const { headline, paragraph } = getContent();

  const handleContinue = () => {
    navigate(`${basePath}/question/9`);
  };

  return (
    <div className="reassurance-page"> 
    {/* <HeaderQuiz questionNumber={questionNumber} totalQuestions={totalQuestions}  /> */}
    <div className="reassurance-page-padding">
    <img className="reassurance-page__image" src="../assets/images/coach-chat.png" alt="Readiness Level" />
      <h1>NO MORE STRESS OVER MAKING MONEY!</h1>
    <p>You'll eliminate financial anxiety by gaining hands-on, financial growth strategies with your AI coach.</p>
    <h2>BEAR IN MIND – it adjusts to your learning curve!</h2>
      {/* <h1 className="transition-page__headline">{headline}</h1>
      <p className="transition-page__paragraph">{paragraph}</p> */}
      {/* <p className="transition-page__disclaimer">For illustrative purposes only.</p> */}
      <button className="reassurance-page__continue" onClick={handleContinue}>Continue</button>
      {/* <Link to={`/question/${questionNumber}`} className="transition-page__go-back">← Go Back</Link> */}
    </div>
    </div>
  );
};

export default ReassurancePage;
