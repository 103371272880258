import React from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import './HeroSectionNew.css';
import { saveUserQuizAnswers } from '../../../api/api';

const HeroSectionNew = ({ setSelectedGender, basePath, funnelName, funnelVersion }) => {
  const navigate = useNavigate();

  const trackSelectGender = (gender) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'dl_select_gender',
        experimentGroup: 'control',
        gender,
      },
    });
  };

  const handleClick = (gender) => {
    trackSelectGender(gender);
    setSelectedGender(gender); // Store the selected gender
    localStorage.setItem('gender', gender);

    const userId = localStorage.getItem("userId");
    if (userId) {
      saveUserQuizAnswers(userId, "Start by selecting your gender", [gender], funnelName, funnelVersion);
    }

    navigate(`${basePath}/question/1`);
  };

  return (
    <div className="ai3-hero-container">
      <img classname="ai3-hero-image" fetchpriority="high" src="../assets/images/ai3-hero.png" alt="AI Career Freedom" className="ai3-hero-image" />
      <h2 className="ai3-hero-title">Achieve career freedom with AI in 3 months</h2>
      <p className="ai3-hero-subtitle">"Wealth is not about luck. It's about improving probabilities. Thanks to Careeristo, I feel more confident, happy, and in control of my life." - Julia</p>
      <div className="ai3-hero-quiz-invite">Start by selecting your gender</div>
      <div className="ai3-hero-gender-selection">
        <div className="ai3-gender-card ai3-male-card" onClick={() => handleClick('male')}>
          <div className="ai3-gender-button ai3-male-button">Income-growth for MEN</div>
        </div>
        <div className="ai3-gender-card ai3-female-card" onClick={() => handleClick('female')}>
          <div className="ai3-gender-button ai3-female-button">Income-growth for WOMEN</div>
        </div>
      </div>
    </div>
  );

};


export default HeroSectionNew;
